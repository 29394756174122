<template>
  <div class="container">
    <Breadcrumb v-if="courseSession && course">
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'courseIntrosList' }">
            {{ $t("pageTitle.courseIntrosList") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'CoursesIntroDetail',
              query: { course_id: course.id }
            }"
          >
            {{ helper.displayI18nText($i18n.locale, course.title) }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'courseSessionsList',
              query: { course_id: course.id }
            }"
          >
            梯次清單
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'courseSessionsDetail',
              params: { id: $route.query.course_session_id }
            }"
          >
            {{ pageTitle }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          班級清單
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Breadcrumb v-else>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ $t("pageTitle.sessionClassesList") }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h3 v-if="courseSession == null">
        {{ $t("pageTitle.sessionClassesList") }}
        <router-link to="/create-class/how-to-billing">
          <i class="fas fa-plus action-icon" />
        </router-link>
      </h3>
      <h3 v-else>
        {{ pageTitle }} - 班級清單
        <el-tooltip
          class="item"
          effect="dark"
          content="創建班級"
          placement="top"
          v-show="isActive"
        >
          <router-link
            :to="
              `/session-classes/create?course_session_id=${$route.query.course_session_id}`
            "
          >
            <i class="fas fa-plus" />
          </router-link>
        </el-tooltip>
      </h3>
      <hr />
    </div>
    <div>
      <el-radio-group v-model="isActive">
        <el-radio-button :label="true">Ongoing</el-radio-button>
        <el-radio-button :label="false">Inactive</el-radio-button>
      </el-radio-group>
      <!-- <el-button
        size="small"
        :type="!this.isActive ? 'default' : 'success'"
        @click="fetchActiveClasses(true)"
      >
        Ongoing
      </el-button>
      <el-button
        size="small"
        :type="this.isActive ? 'default' : 'success'"
        @click="fetchActiveClasses(false)"
      >
        Inactive
      </el-button> -->
    </div>
    <div>
      <el-table :data="classList" style="width: 100%;margin: 20px 0">
        <el-table-column label="Title 班級名稱" prop="title">
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'classDetail', params: { classId: scope.row.id } }"
            >
              {{ scope.row.title }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          v-if="courseSession == null"
          label="Session 所屬梯次"
          prop="course_session.title.en"
        >
        </el-table-column>
        <el-table-column label="Teacher 老師">
          <template slot-scope="scope">
            <div
              :key="teacher.teacher_user_id"
              v-for="teacher in scope.row.active_session_class_teachers"
              class="teacherList"
            >
              {{ teacher.teacher_user.first_name }}
              {{ teacher.teacher_user.last_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Started on 開始日期" prop="started_on">
        </el-table-column>
        <el-table-column label="Ended on 結束日期" prop="ended_on">
        </el-table-column>
        <el-table-column v-if="!isActive" label="結束原因" width="100px">
          <template slot-scope="scope">
            <EndReasonViewer :endReason="scope.row.end_reason || ''" />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'sessionClassesEdit', params: { id: scope.row.id } }"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="編輯班級"
                placement="top"
              >
                <el-button type="primary" size="mini" class="mr-2">
                  <i class="fas fa-edit" />
                </el-button>
              </el-tooltip>
            </router-link>
            <el-tooltip
              class="item"
              effect="dark"
              content="暫停班級"
              placement="top"
              v-show="isActive"
            >
              <el-button type="primary" size="mini"
                @click="setEndLessonId(scope.row.id)"
              >
                <i class="far fa-stop-circle" />
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="繼續班級"
              placement="top"
              v-show="!isActive"
            >
              <el-button type="primary" size="mini"
                v-show="!isActive"
                @click="activeClass(scope.row.id, scope.row)"
              >
                <i class="far fa-play-circle" />
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="刪除班級"
              placement="top"
            >
              <el-button
                type="danger"
                size="mini"
                @click="handleDelete(scope.row.id)"
              >
                <i class="far fa-trash-alt" />
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          :total="listTotal"
        >
        </el-pagination>
      </div>
      <br />
    </div>
    <EndLessonReasonDialog
      :visible="Boolean(handleEndClassId)"
      :classId="handleEndClassId"
      @onCancel="clearEndClassId"
      @onSubmitted="() => {
        clearEndClassId();
        loadClasses();
      }"
    />
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import EndReasonViewer from "@/components/privateLesson/EndReasonViewer";
import EndLessonReasonDialog from "@/views/private/EndLessonReasonDialog";
import roleMixin from "@/mixins/role";
import courseApi from "@/apis/course";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb,
    EndLessonReasonDialog,
    EndReasonViewer
  },

  mixins: [roleMixin],

  props: [],
  data() {
    return {
      isActive: true,
      pageSize: 15,
      currentPage: 1,
      sessionId: 0,
      classList: [],
      courseSession: null,
      course: null,
      listTotal: 0,
      handleEndClassId: null
    };
  },
  computed: {
    helper() {
      return helper;
    },
    pageTitle() {
      if (!this.courseSession) return this.$t("pageTitle.sessionClassesList");
      return `${this.helper.displayI18nText(this.$i18n.locale,
        this.courseSession.title
      )}`;
    }
  },
  watch: {
    isActive(val) {
      this.fetchActiveClasses(val);
    }
  },

  async mounted() {
    await this.loadClasses();
    this.handleCurrentChange(1);
  },

  methods: {
    async loadClasses() {
      let queryParams = {
        course_session_id: this.$route.query.course_session_id,
        is_visible: this.isActive,
        page: this.currentPage
      };
      let sessionClassesRes = await courseApi.fetchSessionClassesForList(
        queryParams
      );
      this.classList = sessionClassesRes.data.map(session_class => ({
        ...session_class,
        teacher: session_class.teacher
          ? session_class.teacher
          : { first_name: "", last_name: "" }
      }));
      this.listTotal = sessionClassesRes.total;

      if (this.$route.query.course_session_id != null) {
        let courseSessionRes = await courseApi.fetchSession(
          this.$route.query.course_session_id
        );
        this.courseSession = courseSessionRes.course_session;

        let courseRes = await courseApi.fetchCourse(
          this.courseSession.course_id
        );
        this.course = courseRes.course;
      }
    },
    async fetchActiveClasses(isActive) {
      this.isActive = isActive;
      await this.loadClasses();
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.loadClasses();
    },
    handleDelete(id) {
      return async () => {
        await courseApi.deleteSessionClass(id);
        await this.loadClasses();
        this.handleCurrentChange(this.currentPage);
      };
    },
    setEndLessonId(id) {
      this.handleEndClassId = id;
    },
    clearEndClassId() {
      this.handleEndClassId = null;
    },
    async activeClass(id) {
      try {
        await courseApi.restartSessionClass(id);
        await this.loadClasses();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped>
.shortcutBtns {
  margin-bottom: 8px;
}

.shortcutBtns > a {
  margin-right: 8px;
}

.teacherList:not(:first-child) {
  border-top: 1px solid #EBEEF5;
  display: block;
}
</style>
